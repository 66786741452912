<template>
    <div>
        <div class="mb-3">
            <router-link to="/admin/spots/sites" class="text-decoration-none">
                <b-button type="is-light" size="is-small" icon-left="backward"> Retourner aux spots</b-button>
            </router-link>
        </div>
        <div class="box">
            <NlTable
                :columns.sync="columns"
                :get-data="loadAsyncData"
                :force-update="forceUpdate"
                detailed
                custom-detail-row
                :loading="isPublishing"
            >
                <template #actions="{ props: { row } }">
                    <div class="d-flex justify-content-start m-auto">
                        <b-button
                            size="is-small"
                            icon-left="plus-circle"
                            icon-pack="fas"
                            type="is-success"
                            @click="publishContentItems(row)"
                            :disabled="!hasCheckedRows(row.content_items)"
                        >
                            Publier
                        </b-button>
                    </div>
                </template>
                <template #detailed="{ props: { row } }">
                    <b-table
                        :columns="detailedColumns"
                        :data="row.content_items"
                        checkable
                        :checked-rows.sync="checkedRows"
                    ></b-table>
                </template>
            </NlTable>
        </div>
        <SpotsPublishModal
            :categories="wordpressCategories"
            :content-items-ids="checkedRows"
            @publish="bulkPublish"
            ref="spotPublishModal"
        />
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import SpotsPublishModal from '../../components/Spots/SpotsPublishModal.vue'

    export default {
        name: 'ContentItems',
        components: { SpotsPublishModal },
        title: 'Contenus spots générés',
        data() {
            return {
                forceUpdate: false,
                checkedRows: [],
                isPublishing: false,
                wordpressCategories: [],
                spotId: null,
                columns: [
                    {
                        sortable: false,
                        searchable: false,
                        custom: true,
                        field: 'actions',
                        label: 'Action',
                        width: '150px'
                    },
                    {
                        field: 'id',
                        label: 'ID'
                    },
                    {
                        field: 'domain',
                        label: 'NDD'
                    }
                ],
                detailedColumns: [
                    {
                        field: 'id',
                        label: 'ID'
                    },
                    {
                        field: 'meta_title',
                        custom: true,
                        searchable: false,
                        sortable: false,
                        label: 'Meta Title',
                        width: '200'
                    },
                    {
                        field: 'tag_title',
                        custom: true,
                        searchable: false,
                        sortable: false,
                        label: 'H1'
                    }
                ],
                detailedForceUpdate: false
            }
        },
        computed: {
            hasCheckedRows() {
                return function (items) {
                    return items.some(itemId => this.checkedRows.includes(itemId))
                }
            }
        },
        methods: {
            ...mapActions('spots', {
                getContentItems: 'getContentItemsBySpot',
                getWordpressCategories: 'getWordpressCategories',
                spotPublishContentItems: 'spotPublishContentItems'
            }),
            loadAsyncData(params) {
                return this.getContentItems({
                    params: params
                })
            },
            publishContentItems(spot) {
                this.spotId = spot.id
                this.checkedRows = this.checkedRows.filter(checkedRow =>
                    spot.content_items.some(item => item.id === checkedRow.id)
                )

                this.getWordpressCategories({ spotId: spot.id }).then(response => {
                    this.wordpressCategories = response.categories
                    this.$refs.spotPublishModal.show()
                })
            },
            bulkPublish(data) {
                this.isPublishing = true
                this.spotPublishContentItems({ spot: this.spotId, data })
                    .then(() => {
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: 'Les contenus ont bien été publiés'
                        })
                        this.forceUpdate = !this.forceUpdate
                    })
                    .catch(() => {
                        this.$store.dispatch('toastError', 'Une erreur est survenue')
                    })
                    .finally(() => {
                        this.checkedRows = []
                        this.spotId = null
                        this.isPublishing = false
                    })
            }
        }
    }
</script>
